/* Custom navbar styles */
.custom-navbar {}

/* Ensures that the navbar links have lighter color for contrast */
.custom-navbar .nav-link,
.nav-btn-rect-to-round {
    color: var(--text);
    transition: color 0.5s ease;
    /* Transition effect for color changes */
}

.custom-navbar .navbar-toggler {
    border-color: var(--text);
}

.custom-navbar .navbar-collapse {
    color: var(--text);

}

/* Hover effect for Nav.Link */
.custom-navbar .nav-link:hover,
.nav-btn-rect-to-round:hover {
    color: var(--primary)
}

.custom-navbar .nav-link.active,
.nav-btn-rect-to-round--active {
    color: var(--primary) !important;
    /* Color transitions to black on hover and when active */
}

.sticky-navbar {
    background: rgb(11, 12, 13);
    background: radial-gradient(circle, rgba(11, 12, 13, 1) 0%, rgba(48, 52, 56, 1) 100%);
    color: var(--text);
    text-shadow: 2px 2px #000;
    background-attachment: fixed;

    position: fixed;
    top: 0;
    left: 0;
    /* Ensure the navbar sticks to the left edge */
    right: 0;
    /* Ensure the navbar sticks to the right edge */
    z-index: 1020;
    /* Bootstrap's recommended z-index for sticky top elements */
}

.navbar-placeholder {

    height: 56px;
    /* Replace with the actual height of your navbar */
}

/* General style adjustments for the navigation button */
.nav-btn-rect-to-round {
    display: inline-block;
    text-align: center;
    line-height: 36px;
    height: 40px;
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    border: 2px solid var(--text);
    color: var(--text);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    text-decoration: none;
}

.nav-btn-rect-to-round:hover {
    border-radius: 60px;
    background: var(--primary);
    /* Change background on hover */
    color: var(--text);
    border-color: var(--primary);
}

.nav-btn-rect-to-round--active {
    color: var(--primary) !important;
    /* Active state text color */
    border-color: var(--primary);
    /* Active state border color */
}

.nav-btn-rect-to-round--active:hover {
    color: var(--background) !important;
    /* Active state text color */
    border-color: var(--primary);
    /* Active state border color */
}


.nav-btn-rect-to-round--white:hover {
    background: var(--primary);
    color: var(--background);
    text-shadow: none;

}