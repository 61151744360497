.property-card {
    overflow: hidden;
    transition: all 0.5s ease;
    position: relative;
    height: 300px;
    border-radius: 10px;
    color: var(--text);
    border-width: 2px;
    border-color: var(--primary);
}

.property-title {
    color: var(--primary);
}


.property-image {
    transition: transform 0.5s ease, filter 0.5s ease;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    filter: blur(1px);
}

.property-description {
    transition: max-height 0.5s ease, padding 0.5s ease, margin 0.5s ease;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 200px;
    background: rgba(35, 40, 46, 0.8);
    padding: 1em;
    margin: 0;
}

.property-card:hover .property-image {
    transform: scale(1.1);
    filter: blur(0px);
}

.property-card:hover .property-description {
    max-height: 0;
    padding: 0;
    margin: 0;
}

.image-modal {
    color: var(--text);
}

.image-modal-header {
    background-color: var(--container-background);
    color: var(--text);
}




.item-image:hover {

    cursor: pointer;
}