.profile-section .profile-image {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.person-name {
    font-weight: 600;
    font-size: 1.75rem;
}

.bio-text {
    font-size: 1rem;
    line-height: 1.5;
}



.section-card {
    color: var(--text);
    background-color: var(--container-background);
    height: 100%;
}

.section-header {
    background-color: var(--container-background);
    color: var(--text);
    font-size: 1.4rem;
    text-align: center;
}

.about-icon {
    color: var(--primary);
}