@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
  --success-color: #28a745;
  --info-color: #17a2b8;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --dark-transparent: rgba(52, 58, 64, 0.3);
  --background-color: #f8f9fa;
  --primary-color: #536878;
  --secondary-color: #301934;
  --light-text-color: #fff;
  --dark-text-color: #000;
  --interact-color: #007bff;

  --text: #FFFFFF;
  --background: #0b0c0d;
  --container-background: #23282E;
  --secondary-container-background: #232E38;
  --primary: #05DDEC;
  --secondary: #771BEE;
  --accent: #e144bc;

}

body {
  background: rgb(11, 12, 13);
  background: radial-gradient(circle, rgba(11, 12, 13, 1) 0%, rgba(48, 52, 56, 1) 100%);
  color: var(--text);
  text-shadow: 2px 2px #000;
  background-attachment: fixed;
  background-position: center;
  font-family: "Mulish", sans-serif;
}

#particles canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.brand-container h1 {
  color: var(--primary);
  font-size: 50px;
}

div.divider {
  background-color: var(--background-color);
  color: white;
  text-align: center;
  height: 2px;
}

.primary-container {
  background-color: var(--dark-transparent);
  color: var(--text);
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
  align-items: center;
}

.DLC{
  display: flex;
  justify-content: center;
}

.letters{
  color: var(--primary);
}
.lettersMid{
  color: var(--container-background);
}

.DLC p{
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

.secondary-container {
  background-color: var(--secondary-container-background);
  color: var(--text);
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px;
}


button.mainButton {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

button.mainButton:hover,
button.mainButton:focus {
  background-color: var(--secondary);
  /* Manually picked darker green */
  color: #ffffcc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-footer {
  /* Replace with your desired color */
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: none;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
}

/* TODO: implement blurred background */
footer{

}
footer img {
  height: 100px;
  /* Sets the height of the image */
  width: auto;
  /* Allows the width to scale proportionally */
}

h1.pageHeader {
  color: var(--primary-color);
}

/* Style for the brand container */
.brand-container {

  text-align: center;
  padding: 20px 0;
}

.brand-container h1 {
  margin-bottom: 0;
  /* Remove margin from h1 if present */
}

.brand-container p {
  font-size: 1.4em;
  margin-top: 5px;
}

.testimonial-card {
  position: relative;
  background-color: var(--dark-transparent);
  color: var(--text);
  height: 100%;
  padding: 10px;
  transition: transform 0.3s ease-in-out;

}


@media (min-width: 992px) {

  /* Applies rotation and hover effects only on lg screens and larger */
  .testimonial-card {
    rotate: 2deg;
  }

  .testimonial-card:hover {
    transform: rotate(-2deg) scale(1.15);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }
}

.testimonial-card:before,
.testimonial-card:after {
  font-family: 'Font Awesome 6 Free', sans-serif;
  /* Ensure the correct font family is used */
  font-size: 30px;
  font-weight: 900;
  position: absolute;
  color: var(--primary);
}

.testimonial-card:before {
  content: '\f10d';
  top: -20px;
  left: -10px;
}

.testimonial-card:after {
  content: '\f10e';
  bottom: -20px;
  right: -10px;
}

.secondary-testimonials-card {
  background-color: var(--secondary-container-background);
  color: var(--text);
  height: 100%;
  display: flex;
  flex-direction: column;


}

.full-width-banner {
  display: block;
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}



#container-divider {
  height: 2px;
  width: 80%;
  background: var(--primary);
  background: linear-gradient(90deg, var(--container-background), var(--primary), var(--container-background));
  margin: 15px auto;
}

#outter-divider {
  height: 2px;
  width: 80%;
  background: var(--primary);
  background: linear-gradient(90deg, hsla(0, 0%, 0%, 0), var(--primary), hsla(0, 0%, 0%, 0));
  margin: 15px auto;


}

.icon-text {
  display: flex;
  align-items: center;
  /* Ensures vertical alignment */
  margin-bottom: 20px;

}
.service-content p {
  font-size: 1rem;
  font-weight: 350;
  color: var(--text);
}
.service-content h5 {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--primary);
  margin-top: 20px;
  margin-bottom: 20px;
}
a {
  font-size: 1rem;
  font-weight: 300;
  color: var(--primary);
  text-decoration: none;
}
a:hover {
  color: var(--interact-color);
}

.icon-text svg {
  margin-right: 20px;
  /* Increase spacing between the icon and text */
  color: #17a2b8;
  /* Bootstrap info color or choose your preferred */
  font-size: 2rem;
  /* Larger icon size for better visual impact */
}

.icon-text h5 {
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 500;
  flex: 0 0 auto;
}

.icon-text p {
  margin-bottom: 0;
  flex-grow: 1;
  font-size: 0.95rem;
}
@media (min-width: 992px) {
  .icon-text {
    margin-bottom: 40px;
  }
  .landing-page{
    height: 80vh;
  }
}

.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.text-center {
  text-align: center;
}

.landing-page h1 {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0.5rem;
}

.landing-page h3 {
  font-size: 1.1875rem;
  font-weight: normal;
}

.clipart-pics {
  height: auto;
  width: 100%;
  max-width: 500px;
}

.clipart-pics {
  height: auto;
  width: 100%;
  max-width: 500px;
}



.gear-container {
  position: relative;
  min-height: 300px;
  min-width: 300px;
}

.gear-position {
  position: absolute;
}

.large-gear {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  font-size: 150px;
}

.small-gear-1 {
  left: 20%;
  top: 20%;
  transform: translate(-50%, -50%) !important;
  font-size: 100px;
}

.small-gear-2 {
  right: 20%;
  top: 20%;
  transform: translate(50%, -50%) !important;
  font-size: 100px;
}

.gear {
  color: var(--text);
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.gear-blue {
  color: var(--primary);
  animation-duration: 7.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.large-gear>.gear-blue {
  /* Ensure correct child selector is used */
  animation-name: rotate-ccw;
}

.small-gear-1>.gear,
.small-gear-2>.gear {
  animation-name: rotate-cw;
}

.gear-blue {
  color: var(--primary);
}

@keyframes rotate-cw {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-ccw {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}


/* By Justin */
/* Buttons for the OurServices Component */
.our-services-button {
  border: white 2px solid;
  color: white;
  height: 60px;
  width: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.our-services-button:focus {
  color: var(--primary);
  border: var(--primary) 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.our-services-button:hover {
  color: white;
  border: var(--primary) 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.btn-active{
  background-color: var(--dark-color);
  color: white;
  border: var(--primary) 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.active-step{
  /* display: block;
  background-color: var(--dark-transparent);
  color: white;
  border: var(--primary) 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: display 0.4s; */
}