.btn {}



.btn-rect-to-round {
    height: 55px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in 0s;
}

.btn-rect-to-round:hover {
    border-radius: 60px;
    color: var(--background) !important;
}

.btn-rect-to-round--blue {
    border: 2px solid var(--primary);
    color: var(--primary) !important;
}

.btn-rect-to-round--blue:hover {
    border-color: var(--primary);
    background: var(--primary);

}


/* Not being used at the moment */
.service-btn-rect-to-round {
    height: 40px;
    width: 150px;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in 0s;
}

.service-btn-rect-to-round:hover {
    border-radius: 60px;
    color: var(--background) !important;
}

.service-btn-rect-to-round--blue {
    border: 2px solid var(--primary);
    color: var(--primary) !important;
}

.service-btn-rect-to-round--blue:hover {
    border-color: var(--primary);
    background: var(--primary);

}

.send-btn-rect-to-round--blue {
    height: 40px;
    width: 75px;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in 0s;
    border: 2px solid var(--primary);
    color: var(--primary);
}

.send-btn-rect-to-round--blue:hover {
    border-color: var(--primary);
    background: var(--primary);
    color: var(--background);
    border-radius: 50px
}

.send-btn-rect-to-round--blue:focus {
    border-color: var(--primary);
    background: var(--primary);
    color: var(--background);
    border-radius: 50px;
    box-shadow: none;  
}