@keyframes animate-roll {
    0% {
        opacity: 0;
        transform: scale(0, 0) rotate(360deg);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1) rotate(0deg);
    }
}

@keyframes animate-flip {
    0% {
        opacity: 0;
        transform: rotateX(-120deg) scale(0.9, 0.9);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg) scale(1, 1);
    }
}

@keyframes stretch {
    0% {
        opacity: 0;
        transform: scale(0, 1);
    }



    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@keyframes animate-grow {
    0% {
        opacity: 0;
        transform: scale(1, 0);
        visibility: hidden;
    }

    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(-100%);
        /* Start off-screen to the left */
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes slide-in-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
        /* Start off-screen to the right */
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.hidden-initially {
    opacity: 0;
    /* Hide elements initially */
}

.slide-in-animation {
    opacity: 0;

    animation-name: slide-in;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;

}

.slide-in-right-animation {
    opacity: 0;

    animation-name: slide-in-right;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;

}

.flip-animation {
    opacity: 0;

    animation-name: animate-flip;
    animation-duration: 1s;
    animation-fill-mode: forwards;

}

.heading-line-animation {
    opacity: 0;

    animation-name: stretch;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;

}

.heading-text-animation {
    opacity: 0;

    animation-name: animate-pop;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;

}

.card-animate-stretch {
    opacity: 0;

    animation-name: stretch;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;
}

.card-animate-pop {
    opacity: 0;

    animation-name: animate-pop;
    animation-duration: 1s;
    transform: scale(0, 1);
    animation-fill-mode: forwards;
}


.card-animate-grow {
    opacity: 0;

    animation-name: animate-grow;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.card-animate-roll {
    opacity: 0;
    animation-name: animate-roll;
    animation-duration: 1s;

    animation-fill-mode: forwards;
}




@keyframes animate-pop {
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
}



.delay-1 {
    animation-delay: 0.3s;
}

.delay-2 {
    animation-delay: 0.6s;
}

.delay-3 {
    animation-delay: 0.9s;
}

.delay-4 {
    animation-delay: 1.2s;
}

.delay-5 {
    animation-delay: 1.5s;
}

.delay-6 {
    animation-delay: 1.8s;
}

.delay-7 {
    animation-delay: 2.1s;
}

.delay-8 {
    animation-delay: 2.4s;
}