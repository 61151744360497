.contact-card {
  transition: background-color 0.5s ease, transform 0.5s ease;

  background-color: var(--container-background);
  color: var(--text);
  height: 100%;
  padding: 10px;
  text-align: center;

}

.contact-card a h3 {
  text-decoration: none;
}

.contact-card.clickable {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.contact-card-icon {
  color: var(--text);
  margin-bottom: 10px;
  transition: color 0.5s ease;
}


.contact-card:hover .contact-card-icon {
  color: var(--primary);
}

.contact-card:hover {

  transform: scale(1.03);
  background-color: var(--secondary-container-background);
}

.form-card {
  position: relative;
  background-color: transparent;
  border-color: transparent;
  color: var(--text);
  height: 100%;
  padding: 10px;
  text-align: left;
}

.form-card input[type="text"],
.form-card input[type="email"],
.form-card textarea,
.contact-text-area:focus {
  background-color: var(--container-background);
  color: var(--text);
  border-color: var(--primary);
  margin-bottom: 10px;
}

.form-card input[type="text"]::selection,
.form-card input[type="email"]::selection,
.form-card textarea::selection,
.contact-text-area::selection {
  background-color: var(--primary);
  color: var(--container-background);
}

.form-card input[type="text"]:focus,
.form-card input[type="email"]:focus,
.form-card textarea:focus,
.contact-text-area:focus {
  box-shadow: 0px 2px 3px var(--primary);
}