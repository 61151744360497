.service-card {
    transition: background-color 0.5s ease, transform 0.5s ease;
    background-color: var(--container-background);
    color: var(--text);
    /* change auto to 100% if I decide to go back to uniform sizing*/
    height: auto;
}

.service-card.clickable {
    cursor: pointer;
    transition: transform 0.3s ease;
}


.service-card-icon {
    color: var(--text);
    margin-bottom: 10px;
    transition: color 0.5s ease;
}

.service-card:hover .service-card-icon {
    color: var(--primary);
}

.service-card:hover {
    transform: scale(1.03);
    background-color: var(--secondary-container-background);
}


.service-card .additional-info {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.service-card .additional-info.show {
    max-height: 1000px;
    transition: max-height 0.5s ease-in;
}

.toggle-info {
    color: var(--primary);
}